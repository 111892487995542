import React, { useCallback, useEffect, useState } from 'react';
import styles from './App.module.css';

// import Graph from './components/Graph';
import GraphPlotly from './components/GraphD3';

const connectionHost = 'http://model.cppmodel.com'
// const connectionHost = 'http://localhost'
const apiPort = 9091



function App() {
  const [inputData, setInputData] = useState([])
  // const [parameterData, setParameterData] = useState([])
  const [outputData, setOutputData] = useState([])
  // const [lastExecution, setLastExecution] = useState(0)
  const queryParams = new URLSearchParams(window.location.search)

  const apiKey = queryParams.get('apiKey') ?? 'fF_HCouWOSfW0857eIg7s89gTGjUycxUI1OFRYBIgw8'
  const simName = queryParams.get('simName') ?? 'cppsimgraph'

  const getData = useCallback((name) => {
    fetch(`${connectionHost}:${apiPort}/simulations/${simName}/latest/${name}`, {
      headers: {
        'Accept': 'application/json',
        'apiKey': apiKey
      },
      method: "POST",
      mode: 'cors'
    }
    )
      .then(function (response) {
        // console.log(response)
        return response.json();
      })
      .then(function (myJson) {
        // console.log(myJson);
        if (name === 'input') {
          setInputData(myJson)
        }
        if (name === 'parameter') {
          // setParameterData(myJson)
        }
        if (name === 'result') {
          setOutputData(myJson)
        }
      });
  }, [setOutputData, setInputData, apiKey, simName])

  // const getSimulations = useCallback(() => {
  //   fetch(`${connectionHost}:${apiPort}/simulations/${simName}/result`, {
  //     headers: {
  //       'Accept': 'application/json',
  //       'apiKey': apiKey
  //     },
  //     method: "POST",
  //     mode: 'cors'
  //   }
  //   )
  //     .then(function (response) {
  //       // console.log(response)
  //       return response.json();
  //     })
  //     .then(function (myJson) {
  //       const lastElement = myJson[myJson.length - 1]
  //       if (lastExecution !== lastElement) {
  //         setLastExecution(lastElement)
  //         getData('result')
  //       }
  //       // console.log(lastElement);
  //       // setOutputData(myJson)
  //     });
  // }, [getData, lastExecution, apiKey, simName])

  useEffect(() => {
    // const id = setInterval(() => {
    // getSimulations()
    // }, 500)
    // return () => clearInterval(id)
    getData('input')
    // getData('parameter')
    getData('result')
  }, [getData])

  return (
    <div className={styles.row}>
      {/* <div className={styles.column}>
        <div className={styles.column}><p>Preparation: Inputs</p></div>
        {
          Object.keys(inputData).map((key) => {
            return (
              <GraphPlotly data={inputData[key]} key={key} title={key} />
            )
          })
        }
      </div> */}
      <div className={styles.column}>
        <div className={styles.column}><p>Results:</p></div>
        {
          Object.keys(outputData).map((key) => {
            return (
              <GraphPlotly inputData={inputData[key]} outputData={outputData[key]} key={key} title={key} />
            )
          })
        }
      </div>
    </div>
  );
}

export default App;
